import styled from "styled-components";

const TaskDetails = styled.div`
  width: 100%;
  .ant-list-item-meta-content {
    width: 100%;
  }
`;

export default TaskDetails;
